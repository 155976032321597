/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { KTSVG } from "../../../helpers";
import { Collection } from "../../../types/collectionTypes";
import ReactPaginate from 'react-paginate';
import { retrieveCollections, setAutoIndex } from "../../../../app/modules/auth/redux/ListingCRUD";
import { NavLink, useHistory } from "react-router-dom";
import { CollectionLine } from "./CollectionLine";
import { useCallback } from 'react';

type Props = {
  className: string;
  innerPadding?: string;
  
};

const TableCollections: React.FC<{
  currentItems: Collection[],
  onAutoIndexChange: () => void
}> = ({
    currentItems,
    onAutoIndexChange
  }) => {
  return (
    <div className="table-responsive">
      <table className="table table-borderless align-middle">
        <thead>
          <tr>
            <th className="p-0 w-50px"></th>
            <th className="p-0 min-w-100px"></th>
            <th className="p-0 min-w-120px"></th>
            <th className="p-0 min-w-70px"></th>
            <th className="p-0 min-w-70px"></th>
            <th className="p-0 min-w-50px"></th>
          </tr>
        </thead>
        <tbody>
        {currentItems && currentItems.map((collection: Collection) => (
          <CollectionLine key={collection._id} collection={collection} onCollectionAutoIndexChange={onAutoIndexChange} className={""}></CollectionLine>
        ))}
        </tbody>
      </table>
    </div>
  )
};

const CollectionsList: React.FC<Props> = ({ className, innerPadding = "" }) => {
  const itemsPerPage = 20;

  const history = useHistory();

  const [searchQuery, setSearchQuery] = useState('');

  const redirect = (url: string) => {
    history.push(url);
  }

  useEffect(() => {
    (async () => {
      const collectionsList = (await retrieveCollections()).data;
      setCollectionsSealed(collectionsList);
    })();
  }, []);

  const [currentItems, setCurrentItems] = useState<Collection[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [collectionsSealed, setCollectionsSealed] = useState<Collection[]>([]);
  const [collections, setCollections] = useState<Collection[]>([]);
  const [autoIndexedOnly, setAutoIndexedOnly] = useState(false);
  const [chainSolana, setChainSolana] = useState(true);
  const [chainAptos, setChainAptos] = useState(true);

  const displayAutoIndexedOnly = async () => {
    /*const newCollections = collectionsSealed.filter((collection: Collection) => {
      if (! autoIndexedOnly) {
        return collection.autoIndex === true
      }

      return collection;
    }).sort((a: Collection, b: Collection): number => {
      if (! a.idCrawlProgress.lastIndexingTime && ! b.idCrawlProgress.lastIndexingTime) return 0;

      if (! a.idCrawlProgress.lastIndexingTime) return 1;

      if (! b.idCrawlProgress.lastIndexingTime) return -1;
      
      return b.idCrawlProgress.lastIndexingTime - a.idCrawlProgress.lastIndexingTime;
    });

    console.log(newCollections)

    if (! autoIndexedOnly) {
      setCollections(newCollections);
    } else {
      setCollections(collectionsSealed);
    }

    setAutoIndexedOnly(! autoIndexedOnly);*/
  };

  useEffect(() => {
    if (! chainSolana || ! chainAptos) {
      const newOffset = 0 * itemsPerPage % (collections.length ?? 0);
      setItemOffset(newOffset);
      setPageCount(1);
    }
  
    let newCollections = collectionsSealed.filter((collection: Collection) => {
      if (chainSolana && chainAptos) {
        return collection;
      }
      else if (! chainSolana && ! chainAptos) {
        return false;
      }
      else if (chainSolana) {
        return collection.chain === 'SOL' || ! collection.chain
      } else if (chainAptos) {
        return collection.chain === 'APTOS';
      }

      return false;
    });

    if (autoIndexedOnly && newCollections.length) {
      newCollections = newCollections.filter((collection: Collection) => collection.autoIndex)
      .sort((a: Collection, b: Collection): number => {
        if (! a.idCrawlProgress.lastIndexingTime && ! b.idCrawlProgress.lastIndexingTime) return 0;
  
        if (! a.idCrawlProgress.lastIndexingTime) return 1;
  
        if (! b.idCrawlProgress.lastIndexingTime) return -1;
        
        return b.idCrawlProgress.lastIndexingTime - a.idCrawlProgress.lastIndexingTime;
      });
    }

    if (searchQuery) {
      newCollections = newCollections.filter((collection: Collection) => {
        const filteredName = collection.name.toLowerCase().replace(/[^A-Z0-9]+/ig, "");
        const filteredSearch = searchQuery.toLowerCase().replace(/[^A-Z0-9]+/ig, "");
  
        return filteredName.includes(filteredSearch);
      });
    }
  
    setCollections(newCollections);
  }, [chainSolana, chainAptos, collectionsSealed, collections.length, autoIndexedOnly, searchQuery]);

  /*useEffect(() => {
    setCollections(collectionsSealed.filter((collection: Collection) => {
      const filteredName = collection.name.toLowerCase().replace(/[^A-Z0-9]+/ig, "");
      const filteredSearch = searchQuery.toLowerCase().replace(/[^A-Z0-9]+/ig, "");

      return filteredName.includes(filteredSearch);
    }));

  }, [searchQuery, collectionsSealed]);*/

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading tokens from ${itemOffset} to ${endOffset}`);
    setCurrentItems(collections.slice(itemOffset, endOffset) ?? []);
    setPageCount(Math.ceil((collections.length ?? 0) / itemsPerPage));
  }, [itemOffset, itemsPerPage, collections]);

   // Invoke when user click to request another page.
   const handlePageClick = (event: any) => {
    const newOffset = event.selected * itemsPerPage % (collections.length ?? 0);
    console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setItemOffset(newOffset);
  };

  const onAutoIndexChange = async () => {
    const collectionsList = (await retrieveCollections()).data;
    setCollectionsSealed(collectionsList);
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className={`card-header border-0 pt-5 ${innerPadding}`}>
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder text-dark fs-3">
            Collections
          </span>
          <span className="text-muted mt-2 fw-bold fs-6">{collections.length}</span>
        </h3>
        <div className="card-toolbar">
          <ul className="nav nav-pills nav-pills-sm nav-light">
          <li className="nav-item">
              <button onClick={() => redirect('/upcoming')} className="btn btn-info"><i className="fas fa-plus fs-4 me-2"></i> Upcoming drops</button>
            </li>
            <li className="nav-item">
              <button onClick={() => redirect('/listing')} className="btn btn-success"><i className="fas fa-plus fs-4 me-2"></i> List a collection</button>
            </li>
          </ul>
        </div>
      </div>
      {/* end::Header */}
      <div
        id="kt_tab_pane_2_1"
        role="tabpanel"
        aria-labelledby="kt_tab_pane_2_1"
        className="tab-pane fade active show"
      >
        <div className="card card-custom mb-10 mb-md-0">
          {/*begin::Body*/}
          <div className="card-body py-10 px-6">
            {/*begin::Search Input*/}
            <div className="d-flex flex-column mb-10 px-3">
              <div
                className="input-group input-group-solid"
                id="kt_chat_aside_search"
              >
                <span className="input-group-text" id="basic-addon1">
                  <KTSVG
                    className="svg-icon-1 svg-icon-dark"
                    path="/media/icons/duotone/General/Search.svg"
                  />
                </span>
                <input
                  type="text"
                  value={searchQuery}
                  onInput={e => setSearchQuery((e.target as HTMLInputElement).value)}
                  className="form-control ps-0 py-4 h-auto"
                  placeholder="Search"
                />
              </div>
              <div className="form-check form-check-custom form-check-solid mt-3">
                <input className="form-check-input" type="checkbox" checked={autoIndexedOnly} onChange={() => setAutoIndexedOnly(! autoIndexedOnly)} id="flexCheckDefault"/>
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Auto indexed collections only
                </label>
              </div>
              <div className="form-check form-check-custom form-check-solid mt-3">
                <input className="form-check-input" type="checkbox" checked={chainSolana} onChange={() => setChainSolana(! chainSolana)} id="chainSolana"/>
                <label className="form-check-label me-5" htmlFor="chainSolana">
                  Solana
                </label>
                <input className="form-check-input" type="checkbox" checked={chainAptos} onChange={() => setChainAptos(! chainAptos)} id="chainAptos"/>
                <label className="form-check-label" htmlFor="chainAptos">
                  Aptos
                </label>
              </div>
            </div>
            {/*end::Search Input*/}

            <TableCollections currentItems={currentItems} onAutoIndexChange={onAutoIndexChange} />
            <ReactPaginate
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel="< previous"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
            />
          </div>
          {/* end::Tap pane */}
        </div>
      </div>
    </div>
  );
};

export { CollectionsList };

