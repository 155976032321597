/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { KTSVG } from "../../../helpers";
import { Collection } from "../../../types/collectionTypes";
import { NavLink, useHistory } from "react-router-dom";
import { setAutoIndex } from './../../../../app/modules/auth/redux/ListingCRUD';
import { useNotification } from "../../../layout/components/notifications/notification";

type Props = {
  className: string;
  innerPadding?: string;
  collection: Collection;
  onCollectionAutoIndexChange: () => void
};

const CollectionLine: React.FC<Props> = ({ className, innerPadding = "", collection, onCollectionAutoIndexChange }) => {
  const history = useHistory();
  const notification = useNotification();

  const [currentCollection, setCurrentCollection] = useState<Collection>();

  const changeAutoIndex = async () => {
        const newValue = ! currentCollection?.autoIndex

        const newCollection = (await setAutoIndex(collection._id, newValue)).data.collection;
        setCurrentCollection(newCollection);

        if (newValue) {
            notification('success', `${newCollection.name} Auto index ON`);
        } else {
            notification('success', `${newCollection.name} Auto index OFF`);
        }

        /*onCollectionAutoIndexChange();*/
  }

  useEffect(() => {
        console.log('test');
        setCurrentCollection(collection);
  }, [collection]);

  return (
    <tr>
        <td className="px-0 py-3">
            <img
            alt="Logo"
            src={collection.image}
            className="mh-60px rounded"
        />
        </td>
        <td className="pt-0 pr-2">
            <NavLink to={`/collection/${collection.id}`}>
            <span className="text-gray-800 fw-bolder text-hover-primary fs-6">
                {collection.name}
            </span>
            </NavLink>
            <span className="text-muted fw-bold d-block mt-1">
            {collection.id}
            </span>
        </td>
        
        <td className="text-end">
            <span className="text-gray-800 fw-bolder d-block fs-6">
            {collection.idCrawlProgress?.seenPieces ?? 0} / {collection.pieces}
            {(collection.pieces - (collection.idCrawlProgress?.seenPieces ?? 0) > 0) && <span className="fw-bolder text-danger"> - {collection.pieces - (collection.idCrawlProgress?.seenPieces ?? 0)} left</span>}
            </span>
            <div className="d-flex align-items-center">
            <div className="progress h-6px  w-100 bg-light-success">
                <div
                className="progress-bar bg-success"
                role="progressbar"
                style={{ width: `${(collection.idCrawlProgress?.seenPieces / collection.pieces) * 100}%` }}
                aria-valuenow={50}
                aria-valuemin={0}
                aria-valuemax={100}
                />
            </div>
            </div>
        </td>
        <td className="text-end">
            <div className="form-check form-switch form-check-custom form-check-solid">
            <input checked={currentCollection?.autoIndex} onChange={changeAutoIndex} className="form-check-input h-20px w-30px" type="checkbox" id="flexSwitch20x30"/>
            <label className="form-check-label" htmlFor="flexSwitch20x30">
                {! currentCollection?.autoIndex && <span className="fw-bolder text-danger">OFF</span>}
                {currentCollection?.autoIndex && <span className="fw-bolder text-primary">ON</span>}
            </label>
            </div>
        </td>
        <td><span className="fw-bolder">{currentCollection?.chain}</span></td>
        <td className="text-end pe-0">
            <button onClick={() => history.push(`/collection/${collection.id}`)} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
            <KTSVG
                className="svg-icon-4"
                path="/media/icons/duotone/Navigation/Arrow-right.svg"
            />
            </button>
        </td>
        </tr>
  );
};

export { CollectionLine };

