/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { createRef } from "react";
import { useEffect } from 'react';
import { AutoIndexState } from "../../../types/collectionTypes";
import { useState } from 'react';
import { toAbsoluteUrl } from "../../../helpers";
import { ProgressBar } from "react-bootstrap-v5";
const { zonedTimeToUtc, utcToZonedTime, format } = require('date-fns-tz')

//export function HeaderNotificationsMenu(MECollecs: MECollection[]) {
type Props = {
    className: string;
    innerPadding?: string;
    autoIndexState?: AutoIndexState;
};

const HeaderNotificationsMenu: React.FC<Props> = ({ className, innerPadding = "", autoIndexState }) => {
  const [autoIndexSt, setAutoIndexSt] = useState<AutoIndexState>();
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    (async () => {
      setAutoIndexSt(autoIndexState);
      //const step = autoIndexState?.collections.indexOf(autoIndexState.currentCollection);
      const step = parseInt(autoIndexState?.step?.split('/')[0] as string);
      
      if (step && autoIndexState) {
        //setPercentage(Math.round(step / autoIndexState?.collections.length * 100));
        setPercentage(Math.round(step / parseInt(autoIndexState?.step.split('/')[1] as string) * 100));
      }
    })();
  }, [autoIndexState]);
 
  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded fw-bold menu-title-gray-800 menu-hover-bg menu-state-title-primary w-250px w-md-300px"
      data-kt-menu="true"
    >
      <div className="menu-item mx-3">
        <div className="menu-content fs-6 text-dark fw-bolder py-6">
          Last action date: {autoIndexSt?.lastIndexingTime}
        </div>
      </div>

      <div className="separator mb-3"></div>

      {/*{MECollecs?.map(MECollec => (
        <MagicEdenLine key={MECollec.symbol} className="" collection={MECollec}></MagicEdenLine>
      ))}*/}

  <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className="card-body pt-5">
        <div className="text-center fw-bolder fs-1 text-gray-800">
          Step {autoIndexSt?.step}
        </div>
        {/* begin::Chart */}
        <div
          className="d-flex flex-center position-relative bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-center h-175px"
          style={{
            backgroundImage: `url('${toAbsoluteUrl(
              "/media/svg/illustrations/bg-1.svg"
            )}')`,
          }}
        >
          <div className="fw-bolder fs-1 text-gray-800 position-absolute">
            {autoIndexSt?.search && <div className="text-center"><div className="spinner-border spinner-border-lg align-middle ms-2"></div><div>Search new tokens</div></div>}
            {! autoIndexSt?.search && <span>{autoIndexSt?.currentCollection}</span>}
          </div>
        </div>
        {/* end::Chart */}

        {/* begin::Items */}
        <div className="pt-1">
          <ProgressBar
            style={{ height: '30px', fontSize: '15px', fontWeight: '900' }} 
            animated 
            variant="success"
            now={percentage}
            label={`${percentage} %`}
           />
        </div>
        {/* end::Items */}
      </div>
      {/* end: Card Body */}
    </div>

      <div className="separator mt-3"></div>

      <div className="menu-item mx-2">
      </div>
    </div>
  );
}

export { HeaderNotificationsMenu };