/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { KTSVG } from "../../../helpers";
import { Collection, Upcoming } from "../../../types/collectionTypes";
import { Link, NavLink, useHistory } from "react-router-dom";
import { useNotification } from "../../../layout/components/notifications/notification";
import clsx from "clsx";
import { deleteUpcoming, getUpcomings } from './../../../../app/modules/auth/redux/ListingCRUD';
import Swal from 'sweetalert2'
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

type Props = {
  className: string;
  innerPadding?: string;
  upcoming: Upcoming;
  onUpcomingsChange: (newValue: Upcoming[]) => void;
  onButtonCreateClick: (newValue: boolean, upcoming?: Upcoming) => void;
};

const UpcomingLine: React.FC<Props> = ({ className, innerPadding = "", upcoming, onUpcomingsChange, onButtonCreateClick }) => {
  const history = useHistory();
  const notification = useNotification();

  const [currentUpcoming, setCurrentUpcoming] = useState<Upcoming>();

  useEffect(() => {
        console.log('test');
        setCurrentUpcoming(upcoming);
  }, [upcoming]);

  const updateUpcomingsList = async () => {
    const upcomingsList = (await getUpcomings()).data;
    onUpcomingsChange(upcomingsList);
  }

  const deleteUpcomingHandler = async (upcoming: Upcoming) => {

    MySwal.fire({
        title: 'Delete this upcoming?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
            deleteUpcoming(upcoming._id).then(() => {
                notification('success', `Upcoming deleted`);
            }).catch(e => {
                notification('error', `Fail deleting the upcoming`);
            }).finally(async () => {
                await updateUpcomingsList();
            })
        }
      });
  };

  return (
    <tr>
        <td className="px-0 py-3">
            <img
            alt="Logo"
            src={upcoming.image}
            className="mh-60px rounded"
        />
        </td>
        <td>
            <span className="text-gray-800 fw-bolder fs-6">
                {upcoming.name}
            </span>
        </td>
        <td>
            <span className="text-gray-800 fw-bolder fs-6">{upcoming.price} SOL</span>
        </td>
        <td>
            <span className="text-gray-800 fw-bolder fs-6">{upcoming.pieces}</span>
        </td>
        <td>
            <span className="text-gray-800 fw-bolder fs-6">{upcoming.date}</span>
        </td>
        <td>
            <span className="text-gray-800 fw-bolder fs-6">{upcoming.time}</span>
        </td>
        <td>
            <button onClick={() => window.open(upcoming.website, "_blank")} disabled={! !!upcoming.website} className={clsx('btn btn-icon btn-google me-5', { 'disabled-btn': upcoming.website === '' })}><i className="fas fa-globe fs-4"></i></button>
            <button onClick={() => window.open(`https://twitter.com/${upcoming.twitter}`, "_blank")} disabled={! !!upcoming.twitter} className={clsx('btn btn-icon btn-twitter me-5', { 'disabled-btn': upcoming.twitter === '' })}><i className="fab fa-twitter fs-4"></i></button>
            <button onClick={() => window.open(`https://discord.com/invite/${upcoming.discord}`, "_blank")} disabled={! !!upcoming.discord} className={clsx('btn btn-icon btn-success me-5', { 'disabled-btn': upcoming.discord === '' })}><i className="fab fa-discord fs-4"></i></button>
        </td>
        <td className="text-end pe-0">
            <button onClick={() => onButtonCreateClick(true, upcoming)} className="btn btn-icon btn-success me-2"><i className="las la-edit fs-2"></i></button>
            <button onClick={() => deleteUpcomingHandler(upcoming)} className="btn btn-icon btn-danger"><i className="las la-trash fs-2"></i></button>
        </td>
        </tr>
  );
};

export { UpcomingLine };

