/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { createCollection } from "../../../../app/modules/auth/redux/ListingCRUD";
import { KTSVG } from "../../../helpers/components/KTSVG";
import { useEffect } from 'react';
import { CrawlProgress } from "../../../types/collectionTypes";
import { Button, Modal, ProgressBar } from "react-bootstrap-v5";
import { Token } from "../../../types/listingTypes";

type Props = {
    show: boolean;
    onHide?: () => void;
    validTokens: Token[],
    id?: string;
    className?: string;
    innerPadding?: string;
    crawlProgress?: CrawlProgress;
};

const RanksCalcModal: React.FC<Props> = ({ show, onHide, validTokens, crawlProgress }) => {
    const [percentageSuccess, setPercentageSuccess] = useState(0);

    useEffect(() => {
        console.log('test')
        if (crawlProgress?.seenPieces && crawlProgress.expectedPieces) {
            let percentage = ((crawlProgress.seenPieces / validTokens.length) * 100).toFixed(2);
            setPercentageSuccess(parseFloat(percentage));
        }
    }, [crawlProgress, validTokens]);

    useEffect(() => {
        console.log('percentageSuccess', percentageSuccess);
    }, [percentageSuccess]);

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Rank calculation
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {percentageSuccess === 0 && <div className="mb-5">Rank calculation is starting...</div>}
                {percentageSuccess === 100 && <div className="mb-5">Rank calculation done !</div>}
                <ProgressBar style={{ height: '30px', fontSize: '15px', fontWeight: '900' }} animated variant="primary" now={percentageSuccess} label={`${crawlProgress?.seenPieces} / ${validTokens.length}`} />
            </Modal.Body>
            <Modal.Footer>
                {percentageSuccess === 100 && <button className="btn btn-success">Close</button>}
            </Modal.Footer>
        </Modal>
    );
};

export { RanksCalcModal };
