import axios from "axios";
import { ListingPayload, ListingRequest, NftMint, RanksExport, Token } from "../../../../_start/types";
import { AutoIndexState, Collection, CrawlProgress, MECollection, Referral, Upcoming } from "../../../../_start/types/collectionTypes";

const API_URL = process.env.REACT_APP_API_URL || "api";

export const COLLECTIONS_LIST_URL = `${API_URL}/collections/`;
export const CREATE_COLLECTION_URL = `${API_URL}/collections/`;
export const LISTING_URL = `${API_URL}/collections/getaccounts`;
export const AUTO_INDEX_STATE_URL = `${API_URL}/collections/autoindexstate`;
export const DOWNLOAD_URL = `${API_URL}/collections/download`;
export const DOWNLOAD_APTOS_URL = `${API_URL}/collections/downloadAptosCollection `;
export const CRAWL_PROGRESS_URL = `${API_URL}/collections/crawlprogress`;
export const AUTO_INDEX_URL = `${API_URL}/collections/autoindex`;
export const GET_COLLECTION_URL = `${API_URL}/collections/`;
export const GET_RANKS_URL = `${API_URL}/collections/:collectionId/ranks`;
export const GET_REFERRAL_URL = `${API_URL}/collections/:collectionId/referral`;
export const POST_TWITTER_URL = `${API_URL}/collections/:collectionId/twitter`;
export const POST_DISCORD_URL = `${API_URL}/collections/:collectionId/discord`;
export const DELETE_MINTS_URL = `${API_URL}/collections/:collectionId/deletemints`;
export const DELETE_COLLECTION_URL = `${API_URL}/collections/:collectionId/delete`;
export const RANKS_CALCULATION = `${API_URL}/collections/:collectionId/rankscalculation`;
export const COLLECTION_CRAWL_PROGRESS_URL = `${API_URL}/collections/crawlprogress`;
export const MAGIC_EDEN_NEW_COLLECTIONS = `https://api-mainnet.magiceden.io/new_collections`;
export const MAGIC_EDEN_COLLECTION = `https://api-mainnet.magiceden.io/rpc/getListedNFTsByQuery`;
export const EXPORT_RANKS_URL = `${API_URL}/collections/:collectionId/export`;
export const EMPTY_COLLECTION_URL = `${API_URL}/collections/:collectionId/empty`;

// Upcoming drops endpoints
export const UPCOMINGS_LIST_URL = `${API_URL}/upcoming/`;
export const UPCOMING_URL = `${API_URL}/upcoming/:upcomingId`;

export function getUpcomings() {
    return axios.get<Upcoming[]>(UPCOMINGS_LIST_URL);
}

export function createUpcoming(upcoming: any) {
    return axios.post(UPCOMINGS_LIST_URL, upcoming);
}

export function getUpcoming(upcomingId: string) {
    return axios.get<Upcoming>(UPCOMING_URL.replace(':upcomingId', upcomingId));
}

export function updateUpcoming(upcomingId: string, upcomingBody: Partial<Upcoming>) {
    return axios.patch<Upcoming>(UPCOMING_URL.replace(':upcomingId', upcomingId), upcomingBody);
}

export function deleteUpcoming(upcomingId: string) {
    return axios.delete<Upcoming>(UPCOMING_URL.replace(':upcomingId', upcomingId));
}

export function retrieveAutoIndexState() {
    return axios.get<AutoIndexState>(AUTO_INDEX_STATE_URL);
}

export function retrieveMECollection(query: string) {
    return axios.get(`${MAGIC_EDEN_COLLECTION}?q=${query}`);
}

export function retrieveNewMECollections() {
    return axios.get<{ collections: MECollection[] }>(MAGIC_EDEN_NEW_COLLECTIONS);
}

export function retrieveCollections() {
    return axios.get<Collection[]>(COLLECTIONS_LIST_URL);
}

export function ranksCalculation(collectionId: string) {
    return axios.post(RANKS_CALCULATION.replace(':collectionId', collectionId));
}

export function deleteMints(collectionId: string, mints: string[]) {
    return axios.post(DELETE_MINTS_URL.replace(':collectionId', collectionId), mints);
}

export function deleteCollection(collectionId: string) {
    return axios.post(DELETE_COLLECTION_URL.replace(':collectionId', collectionId));
}

export function emptyCollection(collectionId: string) {
    return axios.post(EMPTY_COLLECTION_URL.replace(':collectionId', collectionId));
}

export function postTwitter(collectionId: string) {
    return axios.post(POST_TWITTER_URL.replace(':collectionId', collectionId));
}

export function postDiscord(collectionId: string) {
    return axios.post(POST_DISCORD_URL.replace(':collectionId', collectionId));
}

export function getRanks(collectionId: string) {
    return axios.get<NftMint[]>(GET_RANKS_URL.replace(':collectionId', collectionId));
}

export function getReferral(collectionId: string) {
    return axios.get(GET_REFERRAL_URL.replace(':collectionId', collectionId));
}

export function exportRanks(collectionId: string) {
    return axios.post<RanksExport[]>(EXPORT_RANKS_URL.replace(':collectionId', collectionId) + '?tiers=1');
}

export function getCollection(collectionId: string) {
    return axios.get<Collection>(`${GET_COLLECTION_URL}${collectionId}`);
}

export function updateCollection(collectionId: string, collectionBody: Partial<Collection>) {
    return axios.patch<Collection>(`${GET_COLLECTION_URL}${collectionId}`, collectionBody);
}

export function updateCrawlProgress(crawlProgressId: string, crawlProgressBody: Partial<CrawlProgress>) {
    return axios.patch<CrawlProgress>(`${COLLECTION_CRAWL_PROGRESS_URL}/${crawlProgressId}`, crawlProgressBody);
}

export function createCollection(collection: any) {
    return axios.post(CREATE_COLLECTION_URL, collection);
}

export function retrieveTokens(listingRequest: ListingRequest) {
    return axios.post<ListingPayload>(LISTING_URL, listingRequest);
}

export function downloadTokens(
    tokensList: Token[],
    excludedTokensList: Token[],
    collection?: Collection,
    symbol?: string,
    candyMachinesList?: string,
    updateAuthoritiesList?: string,
    slowInsert?: boolean
) {
    if (collection) {
        return axios.post(DOWNLOAD_URL, { 
            tokens: JSON.stringify({ 
                tokens: tokensList,
                excludedTokens: excludedTokensList,
                collection: collection,
                symbol: symbol ?? '',
                candyMachinesList: candyMachinesList ?? '',
                updateAuthoritiesList: updateAuthoritiesList ?? '',
                slowInsert: slowInsert ?? false
            })
        });
    }
}

export function downloadAptosTokens(
    tokensList: string[],
    excludedTokensList: string[],
    collection: Collection,
    identifiers: string[],
    uriType: string
) {
    if (collection) {
        return axios.post(DOWNLOAD_APTOS_URL, { 
            tokens: JSON.stringify({ 
                tokens: tokensList,
                excludedTokens: excludedTokensList,
                collection: collection,
                collectionIdentifiers: identifiers,
                uriType: uriType
            })
        });
    }
}

export function getCrawlProgress(collectionId?: string) {
    if (collectionId) {
        return axios.get<CrawlProgress & { listingRunning: boolean }>(`${CRAWL_PROGRESS_URL}/${collectionId}`);
    }
}

export function setAutoIndex(id: string, autoIndex: boolean) {
    return axios.post(AUTO_INDEX_URL, { id: id, autoIndex });
}

export function getAptosCreatorCollections(creator: string) {
    return axios.get(`${COLLECTIONS_LIST_URL}getAptosCreatorCollections/${creator}`);
}

export function getAptosCollectionMintNames(collectionIdentifiers: string[]) {
    return axios.post(`${COLLECTIONS_LIST_URL}getAptosCollectionMintNames`, { collectionIdentifiers });
}

export function getAptosCollectionTokenData(tokenIdentifier: string) {
    return axios.get(`${COLLECTIONS_LIST_URL}getAptosCollectionTokenData/${tokenIdentifier}`);
}

export function updateCollectionReferral(referralBody: Partial<Referral>) {
    return axios.post<Referral>(`${GET_COLLECTION_URL}referral`, referralBody);
}