/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { ListingModule } from "../../../_start/partials/widgets/tables/ListingModule";
import { ListingPayload, ListingRequest, Token } from "../../../_start/types";
import { useEffect } from 'react';
import { downloadTokens, retrieveTokens, getCrawlProgress, getAptosCollectionMintNames, downloadAptosTokens } from './../../modules/auth/redux/ListingCRUD';
import { Collection, CrawlProgress } from "../../../_start/types/collectionTypes";
import { useNotification } from './../../../_start/layout/components/notifications/notification';
import { Metadata, MetadataProgram } from "@metaplex/js"
import { Connection} from '@solana/web3.js';
//const { Metadata, MetadataProgram } = require('@metaplex/js');

export const ListingPage: React.FC = () => {
  const [listing, setListing] = useState<ListingPayload>({} as ListingPayload);
  const [loading, setLoading] = useState(false);
  const [hasResult, setHasResult] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [collection, setCollection] = useState<Collection>();
  const [candyMachines, setCandyMachines] = useState("");
  const [updateAuthorities, setUpdateAuthorities] = useState("");
  const [identifiers, setIdentifiers] = useState<string[]>([]);
  const [symbol, setSymbol] = useState<string>("");
  const [tokens, setTokens] = useState<Token[]>([]);
  const [excludedTokens, setExcludedTokens] = useState<Token[]>([]);
  const [uriType, setUriType] = useState('UNKNOWN');
  const [rpc, setRpc] = useState('Quiknode');

  const notification = useNotification();

  // Crawl Progress
  const [ranksCalculationLoading, setRanksCalculationLoading] = useState(false);
  const [currentCrawlProgress, setCurrentCrawlProgress] = useState<CrawlProgress>({} as CrawlProgress);
  const [modalCalcShow, setModalCalcShow] = useState(false);

  function handleCollectionChange(newValue: Collection) {
    console.log(newValue.idCrawlProgress);
    if (newValue.idCrawlProgress) {
      if (newValue.idCrawlProgress.candyMachines) {
        setCandyMachines((newValue.idCrawlProgress.candyMachines as string[]).join(','));
      } else {
        setCandyMachines('');
      }

      if (newValue.idCrawlProgress.updateAuthorities) {
        setUpdateAuthorities((newValue.idCrawlProgress.updateAuthorities as string[]).join(','));
      } else {
        setUpdateAuthorities('');
      }

      if (newValue.idCrawlProgress.symbol) {
        setSymbol(newValue.idCrawlProgress.symbol);
      } else {
        setSymbol('');
      }

      if (newValue.idCrawlProgress.uriType) {
        setUriType(newValue.idCrawlProgress.uriType);
      } else {
        setUriType('UNKNOWN');
      }

      if (newValue.idCrawlProgress.identifiers) {
        setIdentifiers(newValue.idCrawlProgress.identifiers);
      } else {
        setIdentifiers([]);
      }
    }
    
    setCollection(newValue);
  }

  function handleCandyChange(newValue: string) {
    setCandyMachines(newValue);
  }

  function handleUpdateChange(newValue: string) {
    setUpdateAuthorities(newValue);
  }

  function handleSymbolChange(newValue: string) {
    setSymbol(newValue);
  }

  function setValidTokens(newValue: Token[]) {
    setTokens(newValue);
  }

  function setExcludedTokensFct(newValue: Token[]) {
    setExcludedTokens(newValue);
  }

  useEffect(() => {
    if (! ranksCalculationLoading) {
      return;
    }

    const interval = setInterval(async () => {
      const crawl: (CrawlProgress & { listingRunning: boolean }) | undefined = (await getCrawlProgress(collection?._id))?.data;

      if (crawl) {
        setCurrentCrawlProgress(crawl);

        if (! crawl.listingRunning) {
          setRanksCalculationLoading(false);
          notification('info', 'Rank calculation done.');
        }
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [collection, notification, ranksCalculationLoading]);

  useEffect(() => {
    if (! loading) {
      return;
    }

    const interval = setInterval(() => {
      setSeconds(seconds => seconds + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [loading]);

  const getProgramAccounts = async (addressesArray: string[], offset: any) => {
    let connections: Connection[] = [];
    if (rpc === 'Quiknode') {
      connections[0] = new Connection('https://nameless-holy-rain.solana-mainnet.quiknode.pro/29d0dfe8ae6747e4489cd0b8fc9ad490769984dc/', 'confirmed');
      connections[1] = new Connection('https://wandering-late-friday.solana-mainnet.discover.quiknode.pro/6101d1ae2dfc5fc9f7eb9c381bdce809a85fd20a/', 'confirmed');
      connections[2] = new Connection('https://stylish-solemn-sound.solana-mainnet.discover.quiknode.pro/20bb46b71722f7d26c62dbbc9aa2a22820ac4365/', 'confirmed');
    } else if (rpc === 'Coral') {
      connections[0] = new Connection('https://rpc.theindex.io/mainnet-beta/799b2f39-9a3b-4fbf-bdbb-93cd3c1e759c', 'finalized');
      connections[1] = new Connection('https://rpc.theindex.io/mainnet-beta/799b2f39-9a3b-4fbf-bdbb-93cd3c1e759c', 'finalized');
      connections[2] = new Connection('https://rpc.theindex.io/mainnet-beta/799b2f39-9a3b-4fbf-bdbb-93cd3c1e759c', 'finalized');   
    } else {
      connections[0] = new Connection('https://nameless-holy-rain.solana-mainnet.quiknode.pro/29d0dfe8ae6747e4489cd0b8fc9ad490769984dc/', 'confirmed');
      connections[1] = new Connection('https://wandering-late-friday.solana-mainnet.discover.quiknode.pro/6101d1ae2dfc5fc9f7eb9c381bdce809a85fd20a/', 'confirmed');
      connections[2] = new Connection('https://stylish-solemn-sound.solana-mainnet.discover.quiknode.pro/20bb46b71722f7d26c62dbbc9aa2a22820ac4365/', 'confirmed');    }

    return await Promise.all(addressesArray.map((address, i) => 
        MetadataProgram.getProgramAccounts(connections[i % 3],
            { filters: [ { memcmp: { offset: offset, bytes: address }}, { memcmp: { offset: 358, bytes: '2' }} ]}
        )
    ));
}

  const getMints = async (collectionBody: any) => {
    let addressesArray: any[] = [];
    let results = [];
    let tokens = [];
    let mergedTokens: any[] = [];
    let offset;

    if (collectionBody.candyMachines) {
        addressesArray = collectionBody.candyMachines.split(',');
        offset = 326
    } else if (collectionBody.updateAuthorities) {
        addressesArray = collectionBody.updateAuthorities.split(',');
        offset = 1;
    } else {
        return [];
    }

    console.log('array is', addressesArray);

    results = await getProgramAccounts(addressesArray, offset);

    let index = 0;

    for (let mintAccounts of results) {
        let decodedData = mintAccounts.map((account: any) => new Metadata(addressesArray[index], account.info).data);

        decodedData = decodedData.filter((metadata: any) => (metadata.data.symbol === collectionBody.symbol && metadata.data.creators[0].verified))

        tokens.push(decodedData.map((decoded: any) => ({
            mint: decoded.mint,
            name: decoded.data.name,
            uri: decoded.data.uri
        })));

        index++;
    }

    for (const token of tokens) {
        mergedTokens = mergedTokens.concat(token);
    }

    const lookup = mergedTokens.reduce((a, e) => {
        a[e.name] = ++a[e.name] || 0;

        return a;
    }, {});

    const duplicatedTokens = mergedTokens.filter((e) => lookup[e.name]);
    const validTokens = mergedTokens.filter(token => ! duplicatedTokens.map(duplicatedToken => duplicatedToken.name).includes(token.name));

    return {
        resultsCount: validTokens.length + duplicatedTokens.length,
        tokens: validTokens,
        duplicates: duplicatedTokens
    };
};

  const retrieveTokensRequest = async () => {
    let listingRequest: ListingRequest = {};

    setHasResult(false);
    setLoading(true);
    setSeconds(0);

    if (collection?.chain === 'APTOS' && identifiers.length) {
      const tokensList = (await getAptosCollectionMintNames(identifiers)).data.mints;
      setListing({
        resultsCount: tokensList.length,
        tokens: tokensList,
        duplicates: []
      });
    } else {
      if (candyMachines) {
        listingRequest.candyMachines = candyMachines.replaceAll(' ', '');
      } else if (updateAuthorities) {
        listingRequest.updateAuthorities = updateAuthorities.replaceAll(' ', '');
      } else {
        return;
      }

      listingRequest.symbol = symbol;

      try {
        const tokensList: any = await getMints(listingRequest); //(await retrieveTokens(listingRequest)).data;
        
        if (! tokensList) {
          setLoading(false);
          notification('error', 'Crawling error');

          return;
        }

        setListing(tokensList);
      } catch (e) {
        console.log('Error during crawling', e);
        notification('error', 'Crawling error');
        setLoading(false);

        return;
      }
    }

    setLoading(false);
    setHasResult(true);
  }

  const downloadTokensRequest = async (slowInsert: boolean) => {
    let candyMachinesList, updateAuthoritiesList;

    if (candyMachines) {
      candyMachinesList = candyMachines.replaceAll(' ', '');
    } else  if (updateAuthorities) {
      updateAuthoritiesList = updateAuthorities.replaceAll(' ', '');
    }
    // here
    console.log(collection?.chain);

    const parsedTokens = tokens.map(token => token.mint);
    const parsedExcludedTokens = excludedTokens.map(token => token.mint);
  
    console.log(parsedTokens);
    console.log(collection);
    console.log(identifiers);
    console.log(uriType);
    
    let startDownload;

    if (collection?.chain === 'APTOS') {
      startDownload = await downloadAptosTokens(parsedTokens, parsedExcludedTokens, collection, identifiers, uriType)?.catch(e => console.log(e));
    } else {
      startDownload = await downloadTokens(tokens, excludedTokens, collection, symbol, candyMachinesList, updateAuthoritiesList, slowInsert)?.catch(e => console.log(e));
    }

    if (! startDownload) {
      notification('error', 'Error starting the download');
    }

    if (startDownload && startDownload.data.success) {
      setModalCalcShow(true);
      setRanksCalculationLoading(true);
      notification('info', startDownload.data.message);
    } else if (startDownload) {
      notification('error', startDownload.data.message);
    }
  }

  const clickModalCalcShow = async (newValue: boolean) => {
    setModalCalcShow(newValue)
  }

  return (
    <>
      {/* begin::Row */}
      <div className="row g-0 g-xl-12 g-xxl-12">
        <div className="col-xl-12">
          <ListingModule
            onClick={() => retrieveTokensRequest()}
            onClickDownload={downloadTokensRequest}
            onClickModalCalcShow={clickModalCalcShow}
            onCollectionChange={handleCollectionChange}
            onCandyChange={handleCandyChange}
            onUpdateChange={handleUpdateChange}
            onSymbolChange={handleSymbolChange}
            onTokensChange={setValidTokens}
            onExcludedTokensChange={setExcludedTokensFct}
            crawlProgress={currentCrawlProgress}
            listing={listing}
            loading={loading}
            ranksCalculationLoading={ranksCalculationLoading}
            hasResult={hasResult}
            setHasResult={setHasResult}
            modalCalcShow={modalCalcShow}
            seconds={seconds}
            className="card-stretch mb-5 mb-xxl-12"
            symbol={symbol}
            candyMachines={candyMachines}
            updateAuthorities={updateAuthorities}
            setUpdateAuthorities={setUpdateAuthorities}
            setCandyMachines={setCandyMachines}
            identifiers={identifiers}
            setIdentifiers={setIdentifiers}
            uriType={uriType}
            setUriType={setUriType}
            rpc={rpc}
            setRpc={setRpc}
          />
        </div>
      </div>
      {/* end::Row */}
    </>
  );
};
