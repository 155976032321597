/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Modal } from "react-bootstrap-v5";
import * as Yup from "yup";
import { createUpcoming, getUpcomings, updateUpcoming } from "../../../../app/modules/auth/redux/ListingCRUD";
import { Upcoming } from "../../../types/collectionTypes";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { useNotification } from "../../../layout/components/notifications/notification";

type Props = {
    show: boolean;
    onHide: (newValue: boolean) => void;
    onUpcomingsChange: (newValue: Upcoming[]) => void;
    id?: string;
    className?: string;
    innerPadding?: string;
    upcoming?: Upcoming;
};

const createUpcomingSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    image: Yup.string().required("Image is required"),
    pieces: Yup.number().positive('Max supply must be greater than zero').required("Max supply is required"),
    price: Yup.number().positive('Max supply must be greater than zero').required("Max supply is required"),
    date: Yup.string(),
    time: Yup.string().required("Time is required"),
    website: Yup.string(),
    twitter: Yup.string(),
    discord: Yup.string()
  });

const FormCreateUpcomingModal: React.FC<Props> = ({ show, onHide, onUpcomingsChange, id, className, innerPadding = "", upcoming }) => {
    const initialValues = {
        name: upcoming ? upcoming.name : "",
        image: upcoming ? upcoming.image : "",
        pieces: upcoming ? upcoming.pieces : 0,
        price: upcoming ? upcoming.price : 0,
        date: upcoming ? upcoming.date : "",
        time: upcoming ? upcoming.time : "",
        website: upcoming ? upcoming.website : "",
        twitter: upcoming ? upcoming.twitter : "",
        discord: upcoming ? upcoming.discord : "",
    };

    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState(new Date());

    const notification = useNotification();

    const handleCalendarClose = () => console.log("Calendar closed");
    const handleCalendarOpen = () => console.log("Calendar opened");

    function handleHide() {
        onHide(true);
    }

    const updateUpcomingsList = async () => {
        const upcomingsList = (await getUpcomings()).data;
        onUpcomingsChange(upcomingsList);
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema: createUpcomingSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true);

            console.log({ ...values, date: format(date, 'MM/dd/yyyy') });

            //return;
            if (upcoming) {
                updateUpcoming(upcoming._id, { ...values, date: format(date, 'MM/dd/yyyy') } as Partial<Upcoming>).then(async upcoming => {
                    await updateUpcomingsList();
                    notification('success', 'Upcoming updated.');
                    handleHide();
                })
                .catch(() => {
                    setStatus("Unable to update upcoming");
                })
                .finally(() => {
                    setLoading(false);
                });
            } else {
                createUpcoming({ ...values, date: format(date, 'MM/dd/yyyy') }).then(async upcoming => {
                    await updateUpcomingsList();
                    notification('success', 'Upcoming created.');
                    handleHide();
                })
                .catch(() => {
                    setStatus("Unable to create upcoming");
                })
                .finally(() => {
                    setLoading(false);
                });
            }
        }
    });

    return (
        <Modal
            show={show}
            onHide={handleHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    {upcoming && (
                        <span>Update upcoming</span>
                    )}
                    {! upcoming && (
                        <span>Create a new upcoming</span>
                    )}
                    </Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit} noValidate>
                <Modal.Body>
                    {formik.status && (
                        <div className="mb-lg-15 alert alert-danger">
                        <div className="alert-text font-weight-bold">{formik.status}</div>
                        </div>
                    )}
                    <div className="form-group mb-5">
                        <label htmlFor="formGroupExampleName">Name</label>
                        <input type="text" {...formik.getFieldProps("name")} className={clsx(
                            "form-control form-control-lg form-control-solid",
                            { "is-invalid": formik.touched.name && formik.errors.name },
                            {
                            "is-valid": formik.touched.name && !formik.errors.name,
                            }
                        )} id="formGroupExampleName" name="name" placeholder="Goblin Lab"/>
                        {formik.touched.name && formik.errors.name && (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.name}</div>
                            </div>
                        )}
                    </div>
                    <div className="form-group mb-5">
                        <label htmlFor="formGroupExampleImage">Image</label>
                        <input type="text" {...formik.getFieldProps("image")} className={clsx(
                            "form-control form-control-lg form-control-solid",
                            { "is-invalid": formik.touched.image && formik.errors.image },
                            {
                            "is-valid": formik.touched.image && !formik.errors.image,
                            }
                        )} id="formGroupExampleId" name="image" placeholder="goblinlab"/>
                        {formik.touched.image && formik.errors.image && (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.image}</div>
                            </div>
                        )}
                    </div>
                    <div className="row">
                        <div className="col">
                            <label htmlFor="formGroupExamplepieces">Max supply</label>
                            <input type="number" {...formik.getFieldProps("pieces")} className={clsx(
                                "form-control form-control-lg form-control-solid",
                                { "is-invalid": formik.touched.pieces && formik.errors.pieces },
                                {
                                "is-valid": formik.touched.pieces && !formik.errors.pieces,
                                }
                            )} id="formGroupExamplepieces" name="pieces" placeholder="2222"/>
                            {formik.touched.pieces && formik.errors.pieces && (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.pieces}</div>
                                </div>
                            )}
                        </div>
                        <div className="col">
                            <label htmlFor="formGroupExampleprice">Price</label>
                            <input type="number" {...formik.getFieldProps("price")} className={clsx(
                                "form-control form-control-lg form-control-solid",
                                { "is-invalid": formik.touched.price && formik.errors.price },
                                {
                                "is-valid": formik.touched.price && !formik.errors.price,
                                }
                            )} id="formGroupExampleprice" name="price" placeholder="2222"/>
                            {formik.touched.price && formik.errors.price && (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.price}</div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label htmlFor="formGroupExampleDate">Date (UTC)</label>
                            <DatePicker
                                className="form-control form-control-lg form-control-solid"
                                selected={date}
                                dateFormat="dd/MM/yyyy"
                                onChange={(date) => setDate(date as unknown as Date)}
                                onCalendarClose={handleCalendarClose}
                                onCalendarOpen={handleCalendarOpen}
                            />
                        </div>
                        <div className="col">
                        <label htmlFor="formGroupExampleTime">Time</label>
                            <input type="text" {...formik.getFieldProps("time")} className={clsx(
                                "form-control form-control-lg form-control-solid",
                                { "is-invalid": formik.touched.time && formik.errors.time },
                                {
                                "is-valid": formik.touched.time && !formik.errors.time,
                                }
                            )} id="formGroupExampleTime" name="time" placeholder="15h00"/>
                            {formik.touched.time && formik.errors.time && (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.time}</div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label htmlFor="formGroupExampleWebsite">Website</label>
                            <input {...formik.getFieldProps("website")} type="text" className="form-control" name="website" id="formGroupExampleWebsite" placeholder="https://solrarity.app"/>
                        </div>
                        <div className="col">
                            <label htmlFor="formGroupExampleTwitter">Twitter</label>
                            <input {...formik.getFieldProps("twitter")} type="text" className="form-control" name="twitter" id="formGroupExampleTwitter" placeholder="SolRarity_"/>
                        </div>
                        <div className="col">
                            <label htmlFor="formGroupExampleDiscord">Discord</label>
                            <input {...formik.getFieldProps("discord")} type="text" className="form-control" name="discord" id="formGroupExampleDiscord" placeholder="solrarity"/>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={() => handleHide()}
                        type="button"
                        className="btn btn-light"
                    >
                        Close
                    </button>
                    <button
                        type="submit"
                        id="kt_login_signin_form_submit_button"
                        className="btn btn-primary fw-bolder fs-6 px-8 py-4 my-3 me-3"
                        disabled={formik.isSubmitting || !formik.isValid}
                        >
                        {!loading && <span className="indicator-label">
                            {upcoming && (
                                <span>Update</span>
                            )}
                            {! upcoming && (
                                <span>Create upcoming</span>
                            )}</span>}
                        {loading && (
                            <span className="indicator-progress" style={{ display: "block" }}>
                            Please wait...{" "}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        )}
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export { FormCreateUpcomingModal };
