/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { FormCreateUpcomingModal } from "../../../_start/partials/widgets/modal/FormCreateUpcomingModal";
import { UpcomingList } from "../../../_start/partials/widgets/tables/UpcomingList";
import { Upcoming } from "../../../_start/types/collectionTypes";
import { getUpcomings } from "../auth/redux/ListingCRUD";

export const UpcomingPage: React.FC = () => {
    const [modalCreateUpcomingShow, setModalCreateUpcomingShow] = useState(false);
    const [currentUpcoming, setCurrentUpcoming] = useState<Upcoming>();
    const [upcomingsSealed, setUpcomingsSealed] = useState<Upcoming[]>([]);

    const clickModal = async (newValue: boolean, upcoming?: Upcoming) => {
      upcoming ? setCurrentUpcoming(upcoming) : setCurrentUpcoming(undefined);

      setModalCreateUpcomingShow(newValue)
  }

    function handleUpcomingsChange(newValue: Upcoming[]) {
      setUpcomingsSealed(newValue);
    }

    useEffect(() => {
      (async () => {
        const upcomingsList = (await getUpcomings()).data;
        setUpcomingsSealed(upcomingsList);
      })();
    }, []);

  return (
    <>
      {/* begin::Row */}
        <div className="row g-0 g-xl-12 g-xxl-12">
            <div className="col-xl-12">
                <FormCreateUpcomingModal 
                    show={modalCreateUpcomingShow}
                    onHide={() => setModalCreateUpcomingShow(false)}
                    onUpcomingsChange={handleUpcomingsChange}
                    upcoming={currentUpcoming}
                />

                <UpcomingList
                    className="card-stretch mb-5 mb-xxl-12"
                    onButtonCreateClick={clickModal}
                    upcomingsList={upcomingsSealed}
                    onUpcomingsChange={handleUpcomingsChange}
                />
            </div>
        </div>
      {/* end::Row */}
    </>
  );
};
