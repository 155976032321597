/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Modal } from "react-bootstrap-v5";
import * as Yup from "yup";
import { createCollection, retrieveCollections } from "../../../../app/modules/auth/redux/ListingCRUD";
import { Collection } from "../../../types/collectionTypes";

type Props = {
    show: boolean;
    onHide: (newValue: boolean) => void;
    onCollectionsChange: (newValue: Collection[]) => void;
    id?: string;
    className?: string;
    innerPadding?: string;
};

const createCollectionSchema = Yup.object().shape({
    chain: Yup.string()
        .required("Name is required"),
    name: Yup.string()
        .required("Name is required"),
    id: Yup.string()
        .required("Id is required"),
    pieces: Yup.number()
        .positive('Max supply must be greater than zero')
        .required("Max supply is required"),
    namePattern: Yup.string(),
    twitter: Yup.string(),
    disabled: Yup.boolean(),
    unranked: Yup.boolean()
  });

const initialValues = {
    chain: "SOL",
    name: "",
    id: "",
    pieces: 0,
    namePattern: '',
    twitter: '',
    disabled: false,
    unranked: false
};

const FormCreateCollecModal: React.FC<Props> = ({ show, onHide, onCollectionsChange, id, className, innerPadding = "" }) => {
    const [loading, setLoading] = useState(false);

    function handleHide() {
        onHide(true);
    }

    function handleCollectionsChange(collections: Collection[]) {
        onCollectionsChange(collections);
    }

    const formik = useFormik({
        initialValues,
        validationSchema: createCollectionSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true);

            createCollection(values).then(async collection => {
                // close modal
                const collectionsList = (await retrieveCollections()).data;
                handleCollectionsChange(collectionsList)
                handleHide();
            })
            .catch((e) => {
                setStatus(e.response.data.message ?? 'Unable to create collection.');
            })
            .finally(() => {
                setLoading(false);
            });
        }
    });

    return (
        <Modal
            show={show}
            onHide={handleHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title>Create a new collection</Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit} noValidate>
                <Modal.Body>
                    {formik.status && (
                        <div className="mb-lg-15 alert alert-danger">
                        <div className="alert-text font-weight-bold">{formik.status}</div>
                        </div>
                    )}
                    <div className="form-group mb-5">
                        <label className="fw-bolder">Chain</label>
                        <select
                        className="form-select form-select-solid form-select-lg"
                        data-control="select2"
                        data-placeholder="Select Category..."
                        data-hide-search="true"
                        value={formik.values.chain}
                        onChange={(e) => formik.setFieldValue('chain', e.target.value)}
                        >
                            <option value="SOL">SOL</option>
                            <option value="APTOS">APTOS</option>
                        </select>
                    </div>

                    <div className="form-group mb-5">
                        <label htmlFor="formGroupExampleName">Name</label>
                        <input type="text" {...formik.getFieldProps("name")} className={clsx(
                            "form-control form-control-lg form-control-solid",
                            { "is-invalid": formik.touched.name && formik.errors.name },
                            {
                            "is-valid": formik.touched.name && !formik.errors.name,
                            }
                        )} id="formGroupExampleName" name="name" placeholder="Goblin Lab"/>
                        {formik.touched.name && formik.errors.name && (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.name}</div>
                            </div>
                        )}
                    </div>
                    <div className="form-group mb-5">
                        <label htmlFor="formGroupExampleId">Id</label>
                        <input type="text" {...formik.getFieldProps("id")} className={clsx(
                            "form-control form-control-lg form-control-solid",
                            { "is-invalid": formik.touched.id && formik.errors.id },
                            {
                            "is-valid": formik.touched.id && !formik.errors.id,
                            }
                        )} id="formGroupExampleId" name="id" placeholder="goblinlab"/>
                        {formik.touched.id && formik.errors.id && (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.id}</div>
                            </div>
                        )}
                    </div>
                    <div className="form-group mb-5">
                        <label htmlFor="formGroupExamplepieces">Max supply</label>
                        <input type="number" {...formik.getFieldProps("pieces")} className={clsx(
                            "form-control form-control-lg form-control-solid",
                            { "is-invalid": formik.touched.pieces && formik.errors.pieces },
                            {
                            "is-valid": formik.touched.pieces && !formik.errors.pieces,
                            }
                        )} id="formGroupExamplepieces" name="pieces" placeholder="2222"/>
                        {formik.touched.pieces && formik.errors.pieces && (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.pieces}</div>
                            </div>
                        )}
                    </div>
                    <div className="form-group mb-5">
                        <label htmlFor="formGroupExampleNamePattern">Name pattern</label>
                        <input type="text" {...formik.getFieldProps("namePattern")} className={clsx(
                            "form-control form-control-lg form-control-solid",
                            { "is-invalid": formik.touched.namePattern && formik.errors.namePattern },
                            {
                            "is-valid": formik.touched.namePattern && !formik.errors.namePattern,
                            }
                        )} id="formGroupExampleNamePattern" name="namePattern" placeholder="Goblin Experiment {id}"/>
                        {formik.touched.namePattern && formik.errors.namePattern && (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.namePattern}</div>
                            </div>
                        )}
                    </div>
                    <div className="form-group mb-5">
                        <label htmlFor="formGroupExampleTwitter">Twitter</label>
                        <input {...formik.getFieldProps("twitter")} type="text" className="form-control" name="twitter" id="formGroupExampleTwitter" placeholder="GoblinLabNFT"/>
                    </div>
                    <div className="mb-10">
                        <div className="form-check form-switch form-check-custom form-check-solid">
                            <input className="form-check-input" type="checkbox" {...formik.getFieldProps("disabled")} id="flexSwitchDefault"/>
                            <label className="form-check-label" htmlFor="flexSwitchDefault">
                                Disabled
                            </label>
                        </div>
                    </div>
                    <div className="mb-10">
                        <div className="form-check form-switch form-check-custom form-check-solid">
                            <input className="form-check-input" type="checkbox" {...formik.getFieldProps("unranked")} id="flexSwitchUnranked"/>
                            <label className="form-check-label" htmlFor="flexSwitchUnranked">
                                Unranked
                            </label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={() => handleHide()}
                        type="button"
                        className="btn btn-light"
                    >
                        Close
                    </button>
                    <button
                        type="submit"
                        id="kt_login_signin_form_submit_button"
                        className="btn btn-primary fw-bolder fs-6 px-8 py-4 my-3 me-3"
                        disabled={formik.isSubmitting || !formik.isValid}
                        >
                        {!loading && <span className="indicator-label">Save changes</span>}
                        {loading && (
                            <span className="indicator-progress" style={{ display: "block" }}>
                            Please wait...{" "}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        )}
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export { FormCreateCollecModal };
