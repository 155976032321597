/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { KTSVG } from "../../../helpers";
import { ListingPayload, Token } from "../../../types";
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import { Collection, CrawlProgress } from "../../../types/collectionTypes";
import { getAptosCollectionTokenData, getAptosCreatorCollections, retrieveCollections } from "../../../../app/modules/auth/redux/ListingCRUD";
import { FormCreateCollectionModal } from "../modal/FormCreateCollectionModal";
import { RanksCalculationModal } from "../modal/RanksCalculationModal";
import { RanksCalcModal } from "../modal/RanksCalcModal";
import { FormCreateCollecModal } from "../modal/FormCreateCollecModal";
import clsx from "clsx";

/**
 * Type 0 valid tokens
 * Type 1 duplicated tokens / excluded tokens
 * @param param0 
 * @returns 
 */
const TableItems: React.FC<{ 
    type: number,
    currentItems: Token[],
    onRemoveToken: (token: Token) => void,
    onAddDuplicateToken: (token: Token) => void,
    onAddExcludedToken: (token: Token) => void,
    onAddExcludedDuplicateToken: (token: Token) => void,
  }> = ({ type, currentItems, onRemoveToken, onAddDuplicateToken, onAddExcludedToken, onAddExcludedDuplicateToken }) => {

    function handleRemoveToken(token: Token) {
      onRemoveToken(token);
    }

    function handleAddDuplicateToken(token: Token) {
      onAddDuplicateToken(token);
    }

    function handleAddExcludedToken(token: Token) {
      onAddExcludedToken(token);
    }

    function handleRemoveDuplicateToken(token: Token) {
      onAddExcludedDuplicateToken(token);
    }

  return (
    <div className="table-responsive">
      <table className="table table-borderless align-middle">
        <thead>
          <tr>
            <th className="p-0"></th>
            <th className="p-0"></th>
            <th className="p-0"></th>
            <th className="p-0"></th>
          </tr>
        </thead>
        <tbody>
        {currentItems && currentItems.map((item: Token) => (
          <tr key={item.mint + item.name}>
            <td className="px-0">
              {item.uri && <a href={`https://explorer.solana.com/address/${item.mint}`} target="_blank" className="text-gray-800 fw-bolder text-hover-primary fs-6" rel="noreferrer">
                {item.name}
              </a>}
              {! item.uri && <span className="text-gray-800 fw-bolder fs-6">{item.name}</span>}
            </td>
            <td>
              {item.uri && <a href={`https://solscan.io/token/${item.mint}`} target="_blank" className="btn btn-outline btn-outline-dashed btn-outline-success btn-active-light-success" rel="noreferrer">View on Solscan</a>}
            </td>
            <td>
              {item.uri && <a href={`${item.uri}`} target="_blank" className="btn btn-light-success" rel="noreferrer">Show metadata</a>}
            </td>
            <td className="pe-0">
              {type === 1 && (<button onClick={() => handleAddDuplicateToken(item)} className="btn btn-icon btn-primary me-1"><i style={{ lineHeight: '0' }} className="las la-plus fs-2"></i></button>)}
              {type === 1 && (<button onClick={() => handleRemoveDuplicateToken(item)} className="btn btn-icon btn-danger"><i style={{ lineHeight: '0' }} className="las la-ban fs-2"></i></button>)}
              {type === 0 && (<button onClick={() => handleRemoveToken(item)} className="btn btn-icon btn-danger"><i style={{ lineHeight: '0' }} className="las la-ban fs-2"></i></button>)}
              {type === 2 && (<button onClick={() => handleAddExcludedToken(item)} className="btn btn-icon btn-primary"><i style={{ lineHeight: '0' }} className="las la-plus fs-2"></i></button>)}
            </td>
          </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
};

type Props = {
  className: string;
  innerPadding?: string;
  onClick?: () => void;
  onClickDownload: (slowInsert: boolean) => void;
  onClickModalCalcShow: (newValue: boolean) => void;
  onCollectionChange: (newValue: Collection) => void;
  onCandyChange: (newValue: string) => void;
  onUpdateChange: (newValue: string) => void;
  onSymbolChange: (newValue: string) => void;
  onTokensChange: (newValue: Token[]) => void;
  onExcludedTokensChange: (newValue: Token[]) => void;
  crawlProgress?: CrawlProgress;
  listing?: ListingPayload;
  loading?: boolean;
  ranksCalculationLoading?: boolean;
  hasResult?: boolean;
  setHasResult: (newValue: boolean) => void;
  modalCalcShow: boolean;
  seconds?: number;
  symbol?: string;
  candyMachines?: string;
  updateAuthorities?: string;
  setUpdateAuthorities: (newValue: string) => void;
  setCandyMachines: (newValue: string) => void;
  identifiers: string[];
  setIdentifiers: (newValue: string[]) => void;
  uriType: string;
  setUriType: (newValue: string) => void;
  rpc: string;
  setRpc: (newValue: string) => void;
}

const ListingModule: React.FC<Props> = ({ 
  className, 
  innerPadding = "", 
  onClick,
  onClickDownload,
  onClickModalCalcShow,
  onCollectionChange,
  onCandyChange,
  onUpdateChange,
  onSymbolChange,
  onTokensChange,
  onExcludedTokensChange,
  crawlProgress,
  listing,
  loading,
  ranksCalculationLoading,
  hasResult,
  setHasResult,
  modalCalcShow,
  seconds,
  symbol,
  candyMachines,
  updateAuthorities,
  setUpdateAuthorities,
  setCandyMachines,
  identifiers,
  setIdentifiers,
  uriType,
  setUriType,
  rpc,
  setRpc
}) => {
  const itemsPerPage = 10;

  const [slowInsert, setSlowInsert] = useState(false);

  const [currentItems, setCurrentItems] = useState<Token[]>([]);
  const [currentDuplicatesTokens, setCurrentDuplicatesTokens] = useState<Token[]>([]);
  const [currentExcludedTokens, setCurrentExcludedTokens] = useState<Token[]>([]);

  const [pageCount, setPageCount] = useState(0);
  const [pageDuplicatesCount, setPageDuplicatesCount] = useState(0);
  const [pageExcludedCount, setPageExcludedCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);
  const [itemDuplicatesOffset, setItemDuplicatesOffset] = useState(0);
  const [itemExcludedOffset, setItemExcludedOffset] = useState(0);

  const [validTokens, setValidTokens] = useState<Token[]>([]);
  const [duplicatesTokens, setDuplicatesTokens] = useState<Token[]>([]);
  const [excludedTokens, setExcludedTokens] = useState<Token[]>([]);

  const [collection, setCollection] = useState<Collection>({} as Collection);
  const [currentCollection, setCurrentCollection] = useState<Collection>({} as Collection);
  const [collections, setCollections] = useState<Collection[]>([]);

  const [modalCollectionShow, setModalCollectionShow] = useState(false);

  const [aptosCreator, setAptosCreator] = useState('');
  const [aptosCreatorCollectionsNames, setAptosCreatorCollectionsNames] = useState<string[]>([]);
  const [aptosCreatorLoading, setAptosCreatorLoading] = useState<boolean>(false);
  const [selectedAptosCollec, setSelectedAptosCollec] = useState('');

  const [metadata, setMetadata] = useState('');
  const [metadataLoading, setMetadataLoading] = useState<boolean>(false);

  function handleCollectionsChange(newValue: Collection[]) {
    setCollections(newValue);
  }

  function handleDownload() {
    onClickDownload(slowInsert);
  }

  useEffect(() => {
    (async () => {
      const collectionsList = (await retrieveCollections()).data;
      setCollections(collectionsList);
    })();
  }, []);

  const sortArray = (a: Token, b: Token) => {
    let aVal = (+a.name.split(' ').slice(-1)[0].replaceAll('#', ''));
    let bVal = (+b.name.split(' ').slice(-1)[0].replaceAll('#', ''));
    if (! isNaN(aVal) && ! isNaN(bVal)) {
        return Number(aVal) - Number(bVal);
    }

    return a.name.localeCompare(b.name);
  };

  function handleRemoveToken(token: Token) {
    setValidTokens(validTokens.filter((validToken: Token) => validToken.uri !== token.uri));
    setExcludedTokens([...excludedTokens, token]);
  }

  function handleAddExcludedDuplicateToken(token: Token) {
    setDuplicatesTokens(duplicatesTokens.filter((duplicateToken: Token) => duplicateToken.mint !== token.mint));
    setExcludedTokens([...excludedTokens, token]);
  }

  function handleAddExcludedToken(token: Token) {
    setExcludedTokens(excludedTokens.filter((excludedToken: Token) => excludedToken.mint !== token.mint));
    setValidTokens([...validTokens, token]);
  }

  function handleAddDuplicateToken(token: Token) {
    setDuplicatesTokens(duplicatesTokens.filter((duplicateToken: Token) => duplicateToken.mint !== token.mint));
    setValidTokens([...validTokens, token]);
  }

  function handleAddAllDuplicatesTokens(tokens: Token[]) {
    setDuplicatesTokens([]);
    setValidTokens(validTokens.concat(tokens));
  }

  useEffect(() => {
      onExcludedTokensChange(excludedTokens);
  }, [onExcludedTokensChange, excludedTokens]);

  useEffect(() => {
    if (validTokens.length) {
      onTokensChange(validTokens);
    }
  }, [onTokensChange, validTokens]);

  useEffect(() => {
      onExcludedTokensChange(excludedTokens);
  }, [onExcludedTokensChange, excludedTokens]);

  useEffect(() => {
    setValidTokens(listing?.tokens ?? []);
  }, [listing?.tokens]);

  useEffect(() => {
    setDuplicatesTokens(listing?.duplicates ?? []);
  }, [listing?.duplicates]);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading tokens from ${itemOffset} to ${endOffset}`);
    setCurrentItems(validTokens.sort(sortArray).slice(itemOffset, endOffset) ?? []);
    setPageCount(Math.ceil((validTokens.length ?? 0) / itemsPerPage));
  }, [itemOffset, itemsPerPage, validTokens]);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemDuplicatesOffset + itemsPerPage;
    console.log(`Loading duplicates from ${itemDuplicatesOffset} to ${endOffset}`);
    setCurrentDuplicatesTokens(duplicatesTokens.sort(sortArray).slice(itemDuplicatesOffset, endOffset) ?? []);
    setPageDuplicatesCount(Math.ceil((duplicatesTokens.length ?? 0) / itemsPerPage));
  }, [itemDuplicatesOffset, itemsPerPage, duplicatesTokens]);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemExcludedOffset + itemsPerPage;
    console.log(`Loading duplicates from ${itemExcludedOffset} to ${endOffset}`);
    setCurrentExcludedTokens(excludedTokens.sort(sortArray).slice(itemExcludedOffset, endOffset) ?? []);
    setPageExcludedCount(Math.ceil((excludedTokens.length ?? 0) / itemsPerPage));
  }, [itemExcludedOffset, itemsPerPage, excludedTokens]);
  
   // Invoke when user click to request another page.
   const handlePageClick = (event: any) => {
    const newOffset = event.selected * itemsPerPage % (validTokens.length ?? 0);
    console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setItemOffset(newOffset);
  };

  // Invoke when user click to request another page.
  const handleDuplicatesPageClick = (event: any) => {
    const newOffset = event.selected * itemsPerPage % (duplicatesTokens.length ?? 0);
    console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setItemDuplicatesOffset(newOffset);
  };

    // Invoke when user click to request another page.
  const handleExcludedPageClick = (event: any) => {
    const newOffset = event.selected * itemsPerPage % (excludedTokens.length ?? 0);
    console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setItemExcludedOffset(newOffset);
  };
  
  function handleCollectionChange(selectCollectionObject: { label: string, value: Collection, key: number }) {
    setUpdateAuthorities('');
    setCandyMachines('');
    setAptosCreatorCollectionsNames([]);
    //setCurrentItems([]);
    setAptosCreator('');
    setIdentifiers([]);
    /*setValidTokens([]);
    setDuplicatesTokens([]);
    setExcludedTokens([]);
    setHasResult(false);*/

    setCurrentCollection(selectCollectionObject.value);
    onCollectionChange(selectCollectionObject.value);
  }

  function handleCandyChange(event: any) {
    onCandyChange(event.target.value);
  }

  function handleUpdateChange(event: any) {
    onUpdateChange(event.target.value);
  }

  function handleSymbolChange(event: any) {
    onSymbolChange(event.target.value);
  }

  function handleModalCalcShow(newValue: boolean) {
    console.log('handleModalCalcShow', newValue);
    onClickModalCalcShow(newValue);
  }

  const searchCreatorCollectionsNames = async () => {
    if (! aptosCreator) {

      return;
    }

    setAptosCreatorLoading(true);

    try {
      const collections = (await getAptosCreatorCollections(aptosCreator)).data.collections;

      setAptosCreatorCollectionsNames(collections);
    } catch (e) {
      console.log(e);
      setAptosCreatorCollectionsNames([]);
    }

    setAptosCreatorLoading(false);
  }

  const stringToHex = (text: string) => {
    const encoder = new TextEncoder();
    const encoded = encoder.encode(text);
  
    return Array.from(encoded, (i) => i.toString(16).padStart(2, "0")).join("");
  }

  const hexToUtf8 = (s: string) => {
    const utf8 = decodeURIComponent(
       s.replace(/\s+/g, '') // remove spaces
        .replace(/[0-9a-f]{2}/g, '%$&') // add '%' before each 2 characters
    );
  
    if (utf8.slice(0, 2) === '0x') {
  
      return utf8.slice(2);
    }
  
    return utf8;
  }

  useEffect(() => {
    console.log('Identifiers', identifiers);
  }, [identifiers]);

  const selectAptosCollec = (creator: string, collectionName: string) => {
    //setSelectedAptosCollec(collectionName);

    if (identifiers.find(identifier => identifier === `${creator}_${stringToHex(collectionName)}`)) {
      // remove
      setIdentifiers(identifiers.filter(identifier => identifier !== `${creator}_${stringToHex(collectionName)}`));
    } else {
      // add
      setIdentifiers([...identifiers, `${creator}_${stringToHex(collectionName)}`]);
    }
  }

  const loadMetadata = async () => {
    const tokenIdentifier = `${currentItems[0].mint}_${stringToHex(currentItems[0].name)}`;
  
    setMetadataLoading(true);
  
    try {
      const metadata = (await getAptosCollectionTokenData(tokenIdentifier)).data.data;

      setMetadata(JSON.stringify(metadata, null, 4));
    } catch (e) {

    }
  
    setMetadataLoading(false);
  }

  const isFindTokensDisabled = () => {
    if (loading) {
      return true;
    }

    if (currentCollection.chain === 'APTOS') {
      if (! identifiers.length) {
        return true;
      }
    } else {
      if (! candyMachines && ! updateAuthorities) {
        return true;
      }
    }

    return false;
  }

  const isStartRankCalculationDisabled = () => {
    if (ranksCalculationLoading) {
      return true;
    }

    if (! currentCollection.name) {
      return true;
    }

    if (currentCollection.chain === 'APTOS') {
      if (uriType === 'UNKNOWN') {
        return true;
      }
    }

    return false;
  }
 
  return (
    <div className={`card ${className}`}>
      <FormCreateCollecModal 
        show={modalCollectionShow} 
        onHide={() => setModalCollectionShow(false)} 
        onCollectionsChange={handleCollectionsChange}
      />
      <RanksCalcModal
        show={modalCalcShow}
        onHide={() => handleModalCalcShow(false)}
        validTokens={validTokens}
        crawlProgress={crawlProgress}
      />
      {/* <!--begin::Header--> */}
      <div className={`pt-10`}>
        <div className="card-toolbar">          
        </div>
      </div>
      {/* <!--end::Header--> */}
      {/* <!--begin::Body--> */}

      <div className="card-body pt-2 pb-0 mt-n3">
        <div className="mb-5" style={{ width: '150px' }}>
          <label className="form-label">Solana RPC</label>
            <select
                className="form-select form-select-solid"
                data-control="select2"
                data-placeholder="Select Category..."
                data-hide-search="true"
                value={rpc}
                onChange={(e) => setRpc(e.target.value)}
                >
                    <option value="Quiknode">Quiknode</option>
                    <option value="Coral">Coral</option>
                </select>
          </div>
        <div className="row align-items-end">
          <div className="col-sm-11">
            <label className="form-label">Collection</label>
            <Select
              className="custom-select"
              value={collection.name}
              onChange={(value) => handleCollectionChange(value as any)}
              options={collections.map((collec: Collection, i: number) => ({
                label: collec.name,
                value: collec,
                key: i
              })) as any}
            />
          </div>
          <div className="col-sm">
            <button onClick={() => setModalCollectionShow(true)} className="btn btn-icon btn-success"><i style={{ lineHeight: '0' }} className="las la-plus fs-2"></i></button>
          </div>
        </div>
        <div className="mt-3 mb-3"></div>
        <span className="badge badge-success mb-3 me-2">{currentCollection.id}</span>
        {currentCollection.name && <span className="badge badge-success">{currentCollection.idCrawlProgress?.seenPieces ?? 0}/{currentCollection.pieces}</span>}
        
        {currentCollection.chain === 'APTOS' && (
          <div className="row align-items-end">
            <div className="col-sm-11">
            <label className="form-label">Get APTOS creator collections list</label>
              <input
                type="text"
                className="form-control"
                value={aptosCreator}
                onChange={(e) => setAptosCreator(e.target.value)}
              />
            </div>
            <div className="col-sm">
              <button disabled={aptosCreatorLoading} onClick={() => searchCreatorCollectionsNames()} className="btn btn-icon btn-success">
              {aptosCreatorLoading && (
                <span
                  className="spinner-border white"
                  role="status"
                >
                </span>
              )}
              {! aptosCreatorLoading && <i style={{ lineHeight: '0' }} className="las la-chevron-right fs-2"></i>}
              </button>
            </div>
            <div className="mt-2">
              {aptosCreatorCollectionsNames.map((collectionName: string) => <span key={collectionName} onClick={() => selectAptosCollec(aptosCreator, collectionName)} className="aptos-collection mb-5"><code className={clsx('aptos-collection-code', { 'aptos-collection-selected': identifiers.includes(`${aptosCreator}_${stringToHex(collectionName)}`) })}>{collectionName}</code></span>)}
              <div>{identifiers.map((collectionEncodedName) => ({ name: hexToUtf8(collectionEncodedName.split('_')[1]), creator: collectionEncodedName.split('_')[0] }))
              .filter((collection: { creator: string, name: string }) => ! aptosCreatorCollectionsNames.includes(collection.name))
              .map((collection: { creator: string, name: string }) => <span key={collection.creator} onClick={() => selectAptosCollec(collection.creator, collection.name)} className="aptos-collection mb-5">
                <code className={clsx('aptos-collection-code', { 'aptos-collection-selected': identifiers.includes(`${collection.creator}_${stringToHex(collection.name)}`) })}>
                  {collection.name}
                </code>
              </span>)}</div>
            </div>
          </div>
        )}
        {currentCollection.chain !== 'APTOS' && (
        <>
          <div className="mb-5">
            <label className="form-label">Candy machines</label>
            <input
              type="text"
              className="form-control"
              value={candyMachines}
              onChange={handleCandyChange}
            />
          </div>
          <div className="mb-5">
            <label className="form-label">Update authorities</label>
            <input
              type="text"
              className="form-control"
              value={updateAuthorities}
              onChange={handleUpdateChange}
            />
          </div>
          <div className="mb-10">
            <label className="form-label">Symbol</label>
            <input
              type="text"
              className="form-control"
              value={symbol}
              onChange={handleSymbolChange}
            />
          </div>
        </>)}
        <div className="d-grid gap-2 mb-8">
          <button onClick={onClick} disabled={isFindTokensDisabled()} className="btn btn-block btn-success">Find tokens {loading && <span className="spinner-border spinner-border-sm align-middle ms-2"></span>}</button>
        </div>
        {loading && (
        <div className="d-flex flex-column w-100 me-3 mt-5 mb-8">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <span className="text-dark me-2 fs-6 fw-bolder">
              Progress
            </span>
          </div>
          <div className="d-flex align-items-center">
            <div className="progress h-6px  w-100 bg-light-success">
              <div
                className="progress-bar bg-success"
                role="progressbar"
                style={{ width: `${(seconds ?? 0) * (rpc === 'Quiknode' ? 0.65 : 25)}%` }}
                aria-valuenow={50}
                aria-valuemin={0}
                aria-valuemax={100}
              />
            </div>
          </div>
        </div>)}
        {hasResult && (
        <div className="flex mt-5">
          <ul className="justify-content-center nav nav-pills nav-pills-sm nav-light">
            <li className="nav-item">
              <a
                className="nav-link btn btn-active-light btn-color-muted py-2 px-4 fw-bolder me-2 active"
                data-bs-toggle="tab"
                href="#kt_tab_pane_1_1"
              >
                Valid tokens <span className="badge badge-square badge-success">{validTokens.length ?? 0}</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link btn btn-active-light btn-color-muted py-2 px-4 fw-bolder me-2"
                data-bs-toggle="tab"
                href="#kt_tab_pane_1_2"
              >
                Duplicates <span className="badge badge-square badge-warning">{duplicatesTokens.length ?? 0}</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link btn btn-active-light btn-color-muted py-2 px-4 fw-bolder"
                data-bs-toggle="tab"
                href="#kt_tab_pane_1_3"
              >
                Excluded <span className="badge badge-square badge-danger">{excludedTokens.length ?? 0}</span>
              </a>
            </li>
          </ul>
          {currentCollection.chain === 'APTOS' && 
            <div className="text-center mb-5 mt-5">
              <button disabled={metadataLoading} onClick={() => loadMetadata()} className="btn btn-light-success">
                {! metadataLoading && <span>Load metadata</span>}
                {metadataLoading && (
                  <span className="indicator-progress" style={{display: "block"}}>
                    Retrieving metadata <span className="spinner-border spinner-border-sm align-middle"></span>
                  </span>
                )}
              </button>
              {metadata && <div><a target="_blank" className="fw-bolder text-hover-primary fs-6" rel="noreferrer" href={JSON.parse(metadata).uri}>{JSON.parse(metadata).uri}</a></div>}
              <textarea value={metadata} placeholder="Metadata will be displayed here..." disabled className="form-control form-control-lg form-control-solid mt-5"></textarea>
            </div>}
          </div>)}
        {hasResult && (
        <div className="tab-content mt-5" id="myTabTables1">
          {/* <!--begin::Tap pane--> */}
          <div
            className="tab-pane fade active show"
            id="kt_tab_pane_1_1"
            role="tabpanel"
            aria-labelledby="kt_tab_pane_1_1"
          >
            <TableItems type={0} 
              currentItems={currentItems} 
              onRemoveToken={handleRemoveToken} 
              onAddDuplicateToken={handleAddDuplicateToken} 
              onAddExcludedToken={handleAddExcludedToken} 
              onAddExcludedDuplicateToken={handleAddExcludedDuplicateToken}
            />
            <ReactPaginate
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel="< previous"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
            />
            <div className="mb-5"></div>
          </div>
          {/* <!--end::Tap pane--> */}

          {/* <!--begin::Tap pane--> */}
          <div
            className="tab-pane fade"
            id="kt_tab_pane_1_2"
            role="tabpanel"
            aria-labelledby="kt_tab_pane_1_1"
          >
            <div className="text-center mb-5">
              <button onClick={() => handleAddAllDuplicatesTokens(duplicatesTokens)} className="btn btn-light-success">Add all duplicates</button>
            </div>
            <TableItems 
              type={1} 
              currentItems={currentDuplicatesTokens}
              onRemoveToken={handleRemoveToken}
              onAddDuplicateToken={handleAddDuplicateToken}
              onAddExcludedToken={handleAddExcludedToken}
              onAddExcludedDuplicateToken={handleAddExcludedDuplicateToken}
            />
            <ReactPaginate
              nextLabel="next >"
              onPageChange={handleDuplicatesPageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageDuplicatesCount}
              previousLabel="< previous"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
            />
            <div className="mb-5"></div>
          </div>
          {/* <!--end::Tap pane--> */}

          {/* <!--begin::Tap pane--> */}
          <div
            className="tab-pane fade"
            id="kt_tab_pane_1_3"
            role="tabpanel"
            aria-labelledby="kt_tab_pane_1_1"
          >
            <TableItems 
              type={2} 
              currentItems={currentExcludedTokens} 
              onRemoveToken={handleRemoveToken} 
              onAddDuplicateToken={handleAddDuplicateToken} 
              onAddExcludedToken={handleAddExcludedToken}
              onAddExcludedDuplicateToken={handleAddExcludedDuplicateToken}
            />
            <ReactPaginate
              nextLabel="next >"
              onPageChange={handleExcludedPageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageExcludedCount}
              previousLabel="< previous"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
            />
          </div>
          <div className="switch switch-icon">
            <div className="form-check form-check-custom form-check-solid form-switch mb-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="slow-insert"
                checked={slowInsert}
                onChange={() => setSlowInsert(! slowInsert)}
              />
            </div>
            <span className="form-check-label text-muted">
              Slow insert
            </span>
            {currentCollection.chain === 'APTOS' && <div className="mt-5 mb-18 form-group mb-5">
                <label className="fw-bolder">URI type</label>
                <select
                  className="form-select form-select-solid form-select-lg"
                  data-control="select2"
                  data-placeholder="Select URI type..."
                  data-hide-search="true"
                  value={uriType}
                  onChange={(e) => setUriType(e.target.value)}
                >
                  <option value="UNKNOWN">---</option>
                  <option value="IMAGE">IMAGE</option>
                  <option value="JSON">JSON</option>
                </select>
            </div>}
          </div>
          <div className="mb-8"></div>
            <div className="d-grid gap-2 mb-5">
              <button onClick={handleDownload} disabled={isStartRankCalculationDisabled()} className="btn btn-block btn-success">Start rank calculation {ranksCalculationLoading && <span className="spinner-border spinner-border-sm align-middle ms-2"></span>}</button>
            </div>
          {/* <!--end::Tap pane--> */}
        </div>)}
      </div>
    </div>
  );
};

export { ListingModule };
