/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { KTSVG } from "../../../helpers";
import { Collection, Upcoming } from "../../../types/collectionTypes";
import ReactPaginate from 'react-paginate';
import { UpcomingLine } from "./UpcomingLine";
import { getUpcomings } from './../../../../app/modules/auth/redux/ListingCRUD';

type Props = {
  className: string;
  innerPadding?: string;
  onButtonCreateClick: (newValue: boolean, upcoming?: Upcoming) => void;
  upcomingsList: Upcoming[];
  onUpcomingsChange: (newValue: Upcoming[]) => void;
};

const TableUpcomings: React.FC<{
  currentItems: Upcoming[],
  onUpcomingsChange: (newValue: Upcoming[]) => void;
  onButtonCreateClick: (newValue: boolean, upcoming?: Upcoming) => void;
}> = ({
    currentItems,
    onUpcomingsChange,
    onButtonCreateClick
  }) => {
  return (
    <div className="table-responsive">
      <table className="table table-borderless align-middle">
        <thead>
          <tr>
            <th className=""></th>
            <th className=""></th>
            <th className=""></th>
            <th className=""></th>
            <th className=""></th>
            <th className=""></th>
            <th className=""></th>
            <th className=""></th>
          </tr>
        </thead>
        <tbody>
        {currentItems && currentItems.map((upcoming: Upcoming) => (
          <UpcomingLine key={upcoming._id} upcoming={upcoming} className={""} onUpcomingsChange={onUpcomingsChange} onButtonCreateClick={onButtonCreateClick}></UpcomingLine>
        ))}
        </tbody>
      </table>
    </div>
  )
};

const UpcomingList: React.FC<Props> = ({ className, innerPadding = "", onButtonCreateClick, upcomingsList, onUpcomingsChange }) => {
  const itemsPerPage = 20;

  const [searchQuery, setSearchQuery] = useState('');

  const [currentItems, setCurrentItems] = useState<Upcoming[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [upcomingsSealed, setUpcomingsSealed] = useState<Upcoming[]>([]);
  const [collections, setCollections] = useState<Upcoming[]>([]);

  useEffect(() => {
    (async () => {
      setUpcomingsSealed(upcomingsList);
    })();
  }, [upcomingsList]);

  useEffect(() => {
    setCollections(upcomingsSealed.filter((upcoming: Upcoming) => {
      const filteredName = upcoming.name.toLowerCase().replace(/[^A-Z0-9]+/ig, "");
      const filteredSearch = searchQuery.toLowerCase().replace(/[^A-Z0-9]+/ig, "");

      return filteredName.includes(filteredSearch);
    }));
  }, [searchQuery, upcomingsSealed]);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading tokens from ${itemOffset} to ${endOffset}`);
    setCurrentItems(collections.slice(itemOffset, endOffset) ?? []);
    setPageCount(Math.ceil((collections.length ?? 0) / itemsPerPage));
  }, [itemOffset, itemsPerPage, collections]);

   // Invoke when user click to request another page.
   const handlePageClick = (event: any) => {
    const newOffset = event.selected * itemsPerPage % (collections.length ?? 0);
    console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setItemOffset(newOffset);
  };

  const updateUpcomingsList = async () => {
    const upcomingsList = (await getUpcomings()).data;
    onUpcomingsChange(upcomingsList);
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className={`card-header border-0 pt-5 ${innerPadding}`}>
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder text-dark fs-3">
            Upcoming collections
          </span>
          <span className="text-muted mt-2 fw-bold fs-6">{collections.length}</span>
        </h3>
        <div className="card-toolbar">
          <ul className="nav nav-pills nav-pills-sm nav-light">
            <li className="nav-item">
              <button onClick={() => onButtonCreateClick(true)} className="btn btn-warning"><i className="fas fa-plus fs-4 me-2"></i> Add an upcoming</button>
            </li>
          </ul>
        </div>
      </div>
      {/* end::Header */}
      <div
        id="kt_tab_pane_2_1"
        role="tabpanel"
        aria-labelledby="kt_tab_pane_2_1"
        className="tab-pane fade active show"
      >
        <div className="card card-custom mb-10 mb-md-0">
          {/*begin::Body*/}
          <div className="card-body py-10 px-6">
            {/*begin::Search Input*/}
            <div className="d-flex flex-column mb-10 px-3">
              <div
                className="input-group input-group-solid"
                id="kt_chat_aside_search"
              >
                <span className="input-group-text" id="basic-addon1">
                  <KTSVG
                    className="svg-icon-1 svg-icon-dark"
                    path="/media/icons/duotone/General/Search.svg"
                  />
                </span>
                <input
                  type="text"
                  value={searchQuery}
                  onInput={e => setSearchQuery((e.target as HTMLInputElement).value)}
                  className="form-control ps-0 py-4 h-auto"
                  placeholder="Search"
                />
              </div>
            </div>
            {/*end::Search Input*/}

            <TableUpcomings currentItems={currentItems} onUpcomingsChange={onUpcomingsChange} onButtonCreateClick={onButtonCreateClick} />
            <ReactPaginate
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel="< previous"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
            />
          </div>
          {/* end::Tap pane */}
        </div>
      </div>
    </div>
  );
};

export { UpcomingList };

